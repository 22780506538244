import React from "react";
import NavBar from "../components/NavBar.js";
import PrivacySection from "../components/PrivacySection.js";
import Footer from "../components/FooterSection.js";




function PrivacyPage(){


    return (
        <>
        <NavBar/>
        <PrivacySection/>
        <Footer/>
        </>
    )
}

export default PrivacyPage;