import React from "react";
import NavBar from "../components/NavBar.js";
import AboutSection from "../components/AboutSection.js";
import Footer from "../components/FooterSection.js";




function AboutPage(){


    return (
        <>
        <NavBar/>
        <AboutSection/>
        <Footer/>
        </>
    )
}

export default AboutPage;